<script lang="jsx">
import TransformImg from '../transformImg/transformImgProduct.vue'

const { LAZY_IMG_SOLID_COLOR = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

export default {
  name: 'CropImageContainer',
  functional: true,
  components: {
    TransformImg
  },
  props: {
    /**
     * @param {Boolean} isSupportCropImage SSR渲染 是否支持补图 用于Apollo控制放量
     * @param {String} imgSrc 图片原始地址
     * @param {Object} transformImgProps 透传 transformImg 组件props
     * @param {String} containerBackground 容器背景颜色
     * @param {String} fixedRatio 容器固定宽高比 （宽-高）例如 fixedRatio="3-4"
     * @param {String} ignoreImgExt 忽略根据图片地址后缀决定容器比例的逻辑
     * */
    isSupportCropImage: {
      type: Boolean,
      default: false
    },
    imgSrc: {
      required: true,
      type: String,
      default: ''
    },
    containerBackground: {
      type: String,
      default: '#FFF'
    },
    transformImgProps: {
      type: Object,
      default: () => {
        return {}
      }
    },
    fixedRatio: {
      type: String,
      default: ''
    },
    ignoreImgExt: {
      type: Boolean,
      default: false
    },
    ignoreMask: {
      type: Boolean,
      default: false
    },
    imgThumb: {
      type: String,
      default: ''
    },
  },
  render(h, { props, data, slots, listeners }) {
    const { imgSrc, containerBackground, fixedRatio, ignoreImgExt, ignoreMask, transformImgProps, imgThumb } = props

    const isHitApollo = () => {
      // Apollo 已经推全，不再需要传递 isSupportCropImage
      return true
      // if (typeof window === 'undefined') {
      //   return isSupportCropImage
      // }

      // const { RESOURCE_SDK } = gbCommonInfo
      // if  (RESOURCE_SDK && RESOURCE_SDK.isSupportCropImage) {
      //   return true
      // }

      // return false
    }


    const isSquareSrc = () => {
      // 未命中 Apollo 依旧使用未被去后缀的图片地址
      if (!isHitApollo()) {
        return false
      }
      
      return imgSrc.includes('_square')
    }

    const isSqufixSrc = () => {
      // 未命中 Apollo 依旧使用未被去后缀的图片地址
      if (!isHitApollo()) {
        return false
      }
      
      return imgSrc.includes('_squfix')
    }

    const isNotExtSrc = () => {
      return !isSqufixSrc() && !isSquareSrc()
    }

    const containerRatio = () => {
      if (fixedRatio === '3-4') {
        return 4 / 3
      }
      
      if (fixedRatio === '4-5') {
        return 5 / 4
      }

      if (fixedRatio === '5-6') {
        return 6 / 5
      }

      if (fixedRatio === '13-16') {
        return 16 / 13
      }

      if (fixedRatio === '14-17') {
        return 17 / 14
      }
      if (fixedRatio === '1-1') {
        return 1 / 1
      }
      
      // 以下是对非固定容器宽高比 根据imgSrc地址确定的容器大小
      if (isSquareSrc()) {
        // 1 : 1
        return 1 / 1
      }
      
      // 3 : 4
      return 4 / 3
    }

    const cropContainerStyle = () => {
      const backdropContainerStyle = {
        backgroundColor: `${containerBackground}`,
      }

      const containerRatioStyle = {
        paddingBottom: `calc(${containerRatio().toFixed(2)} * 100%)`,
      }

      const containerStyle = {
        ...containerRatioStyle,
        ...backdropContainerStyle
      }

      if (ignoreImgExt) {
        return containerStyle
      }

      if (isNotExtSrc()) {
        return containerRatioStyle
      }

      return containerStyle
    }

    // 未命中 Apollo 依旧使用未被去后缀的图片地址
    const originImgSrc = !isHitApollo() ? imgSrc : imgSrc.replace(/(_square)|(_squfix)/ig, '')

    let imgClassName = 'crop-image-container__img'

    if (typeof transformImgProps.imgClassName === 'string') {
      imgClassName = `${transformImgProps.imgClassName} crop-image-container__img`
    }

    if (Array.isArray(transformImgProps.imgClassName)) {
      imgClassName = [
        ...transformImgProps.imgClassName,
        'crop-image-container__img'
      ]
    }

    const forwardProps = {
      props: {
        ...transformImgProps,
        imgClassName
      }
    }

    // 没传给默认值 LAZY_IMG_SOLID_COLOR
    if (forwardProps.props.loadImg === undefined && LAZY_IMG_SOLID_COLOR) {
      forwardProps.props.loadImg = LAZY_IMG_SOLID_COLOR
    }

    const handleClickImage = () => {
      listeners?.['clickImage']?.()
    }

    const handleImgLoadSucc = (e) => {
      listeners?.['imgLoadSucc']?.(e)
    }
    const handleImgLoadFail = (e) => {
      listeners?.['imgLoadFail']?.(e)
    }

    let maskEle = null

    if (!ignoreMask) {
      maskEle = (
        <div class="crop-image-container__mask"></div>
      )
    }

    const handleTransformImgEl = ({ notFsp } = {}) => {
      return (<TransformImg
        {...forwardProps}
        class={ notFsp ? 'not-fsp-element' : '' }
        imgSrc={originImgSrc}
        vOn:imgLoadSucc={handleImgLoadSucc}
        vOn:imgLoadFail={handleImgLoadFail}
      >
        {
          maskEle
        }
      </TransformImg>)
    }

    return (
      <div
        class={[
          'crop-image-container',
          data.class,
          data.staticClass,
        ]}
        style={cropContainerStyle()}
        data-before-crop-src={imgSrc}
        data-crop-rate={(containerRatio())}
        vOn:click={handleClickImage}
      >
        {imgThumb && isNotExtSrc ? (
          <div
            class="crop-image-container__thumb fsp-element"
            style={{ 'background-image': `url(${imgThumb})` }}
          >
            {handleTransformImgEl({ notFsp: true })}
          </div>
        ) : (
          handleTransformImgEl()
        )}

        {
          slots().content ? slots().content : null
        }
      </div>
    )
  }
}
</script>

<style lang="less">
.crop-image-container {
  position: relative;
  overflow: hidden;
  height: 0;

  /* postcss-shein:ignore */
  &__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: var(--crop-img-fit, contain);
    object-position: var(--crop-object-position, center);
  }

  &__thumb {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: 0 0 !important; /* stylelint-disable-line declaration-no-important */
    background-size: cover !important; /* stylelint-disable-line declaration-no-important */
  }

  .addbag-normal-dialog {
    .S-dialog__normal {
      width: 5.87rem;
      min-height: 3.33rem;
      /* stylelint-disable-next-line */
      .S-dialog__body {
        padding: .6rem .4rem .47rem;
        text-align: center;
        position: relative;
        font-size: 12px;
        line-height: unset;
        /* stylelint-disable-next-line */
        .iconfont {
          text-align: center;
          margin: -.4rem auto .2rem;
          display: block;
          font-size: 45px;
        }
      }
      /* stylelint-disable-next-line */
      .S-dialog__footer {
        padding: 0;
      }
    }
  }

  &__mask {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.03);
  }
}
</style>
